import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './styles.scss';

/**
 * Wrap children in a div and apply a class to itself when the
 * component becomes visible in the window. Visiblity is configured
 * via the 'threshold' and 'margin' attributes.
 *
 * Note: THIS IS NOT SUPPORTED BY IE. IE will Toggle the class instantly, ignoring visibility.
 */
const ToggleClassOnVisible = ({
  children,
  isVisibleClass,
  threshold,
  margin,
  delay,
  extraClasses,
  ...other
}) => {
  const targetRef = useRef(null);
  const observerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const classes = classNames(
    'visibility-observer',
    extraClasses,
    isVisible && isVisibleClass
  );

  useEffect(() => {
    const options = {
      rootMargin: `${margin}px`,
      threshold: threshold,
    };

    // IE won't support this. Set as visible and return.
    if (!('IntersectionObserver' in window)) {
      setIsVisible(true);
      return;
    }

    // Create observer
    observerRef.current = new IntersectionObserver((entries, observer) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        if (delay) {
          setTimeout(() => setIsVisible(true), delay);
        } else {
          setIsVisible(true);
        }
        observer.disconnect();
      }
    }, options);
    // Observe target
    observerRef.current.observe(targetRef.current);

    // Disconnect on dismount.
    return () => {
      if (observerRef.current == null) return;
      observerRef.current.disconnect();
    };
  }, [threshold, margin, delay]);

  return (
    <div className={classes} ref={targetRef} {...other}>
      {children}
    </div>
  );
};

ToggleClassOnVisible.propTypes = {
  /** The classname to be applied when the element becomes visible */
  isVisibleClass: PropTypes.string,
  /** How much of the element should be visble. 0 to 1 */
  threshold: PropTypes.number,
  /** How much of the viewport (in pixels) should be ignored when calculating threshold. */
  margin: PropTypes.number,
  /** The delay in millseconds between when the observer triggers and the class is applied. */
  delay: PropTypes.number,
  /** Any extra classes needed for the wrapper. */
  extraClasses: PropTypes.string,
};

ToggleClassOnVisible.defaultProps = {
  isVisibleClass: 'is-visible',
  threshold: 0.15,
  margin: 0,
  delay: 0,
  extraClasses: '',
};

export default ToggleClassOnVisible;
